import React from 'react'
import Link from 'next/link'
import RoundedBox from '@/components/RoundedBox'
import { CollectionsItemType } from '@/api/resources/user/tracker/drops/get/schema'
import { findDropConfigById } from '@/config/drops'
import moment from 'moment'
import { dateToMoment } from '@/util/time'
import { DropState } from '@/enum'
import { isRewardsDrop } from '@/util/dropHelpers'

const CollectionBanner = ({
  collection
}: {
  collection: CollectionsItemType
}) => {
  const drop = findDropConfigById(collection.id)

  if (!drop) return null

  const { time_launch, state, id } = drop
  const isStillAvailable =
    dateToMoment(time_launch).isBefore(moment()) &&
    state !== DropState.SaleEnded &&
    state !== DropState.SoldOut

  const url = isRewardsDrop(drop)
    ? drop.rewardsUrl
    : `/reserve-drop/?drop_id=${id}`

  if (isStillAvailable) {
    return (
      <RoundedBox className="!bg-blue mb-3 text-center !py-3 !px-2">
        <h3 className="h5">Packs are still available!</h3>
        <p className="body-sm mt-1">
          Time’s running out, there are still packs up for grabs.{' '}
          <Link href={url} target="_blank" className="underline">
            Buy Now
          </Link>
        </p>
      </RoundedBox>
    )
  }

  return null
}

export default CollectionBanner
